#photo-and-intro{
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 0 5% 0 5%;
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 300;
}

#photo-wrapper,
#myphoto{
    border-radius: 50%;
    width: 20vw;
    height: 20vw;
    min-width: 150px;
    min-height: 150px;
    margin: 2%;
}

#aboutmetext{
    display: flex;
    width: 70%;
    margin: 2%;
    font-size: max(1.6vw, 15px);
}


#lang-filtering-nav{
    width: 80%;
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 0 10% 0 10%;
    background-color: #292939;
    font-weight: 300;
    padding: 1% 0 1% 0;
}


#lang-filter-all,
#lang-filter-front,
#lang-filter-back,
#lang-filter-db,
#lang-filter-test{
    margin: 0;
    padding: 10px;
}
#lang-filtering-nav p:hover{
    border-radius: 30px;
    margin: 0;
    transition: background-color 0.3s;
    background-color: #8f3965;
}

#all-icons-wrapper{
    width: 80%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    display: flex;
    margin: 0 10% 0 10%
}

.lang-wrapper{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 2%;
}

.deviconWrapper{
    border-radius: 50%;
    background-color: white;
    width: 10vw;
    height: 10vw;
    min-width: 90px;
    min-height: 90px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.devicon{
    width: 65%;
    min-width: 60px;
}

#lang-name{
margin: 3px 0 0 0 ;
color: white;
font-weight: 300;
}

.lang-filter-active{
    background-color: #DF599E;
    border-radius: 30px;
}


@media (max-width: 768px) {
    #photo-and-intro{
        flex-direction: column;
    }

    #aboutmetext{
        width: 90%;
        margin:0
    }

    #lang-filtering-nav{
        flex-wrap: wrap;
    }


}