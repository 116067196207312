#projectpage-title{
    margin: 2% 10% 0 10%;
    font-weight: 500;
}

#projectpage-intro{
    text-align: center;
    margin: 2% 7% 0 7%;
    font-weight: 300;
    font-size: 1.6vw;
}

#projectpage-url{
    text-align: center;
    margin: 3px 10% 0 10%;
    justify-content: center;
    align-items: center;
    display: flex;
    text-decoration: none;
    color: #8870AC;
    font-size: 1.4vw;
}

#mock-up-features-wrapper{
    display: flex;
    margin: 2% 5% 0 5%;
    font-weight: 300;
}

#mock-up-wrapper{
    position: relative;
    width: 45%;
}

#computer-mock-up{
    width: 100%;  
    z-index: 1;
    position: relative;
}

#demo-gif{
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 3.7%;
    margin-left: 2%;
    width: 97.5%;
}

#mockup-credit{
    color: rgba(255, 255, 255, 0.442);
    font-size: 0.8vw;
    display: flex ;
    justify-content: center;
}

#features{
    width: 55%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.6vw;
}

#feature-list-bold{
    font-weight: 600;
}


#key-features-title{
    align-items: center;
    justify-content: center;
    display: flex;
    margin: 0;
    font-weight: 600;
}


#tech-stack-wrapper{
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 2% 5% 0 5%;
    justify-content: center;
}

#tech-stack{
    background-color: #c44d8a;
    border-radius: 10px;
    width: 15vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2% 2% 0 2%;
    font-weight: 300;
}

#stack-title{
    font-size: max(2vw, 15px);
    height: 15%;
    margin: 5% 0 0 0;
    font-weight: 500;
}

#stack-desc{
    padding: 3%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 3% 0 3% 0;
}

#each-lang{
    background-color: rgba(25, 21, 39, 0.159);
    margin: 2%;
    border-radius: 30px;
    padding: 7% 15% 7% 15%;
    width: fit-content;
}

#homepage-sc-wrapper{
    width: 90%;
    margin: 2% 5% 0 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#homepage-sc-img{
    width: 100%;
    border-radius: 10px;
}

#usage-step-wrapper{
    display: flex;
    flex-direction: row;
    width: 90%;
    margin: 2% 5% 0 5%;
    background-color: #292939;
    border-radius: 10px;
}

#usage-num-description{
    width: 30%;
    font-weight: 300;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

#step-num{
    text-align: center;
    font-weight: 400;
}

#step-desc{
    font-size: max(1.5vw, 16px);
    text-align: center;
    padding: 0 3% 0 3%;
}

#usage-title{
    font-weight: 500;
}

#usage-img{
    display: flex;
    position: relative;
    width: 70%;
    border-radius: 10px;
    margin: 5px 5px 5px 0;
}

#usage-img img{
    width: 100%;
    border-radius: 10px;
}

#back-to-projects{
	display:flex;
    width: 20%;
    margin: 2% 40% 2% 40%;
    justify-content: center;
    align-items: center;
    font-weight: 400;
	padding: .50rem 0.75rem;
	border-radius: 10rem;
	color: #fff;
    text-decoration:none;
	transition: all .3s;
	position: relative;
	overflow: hidden;
	z-index: 1;
	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #8f3965;
		border-radius: 10rem;
		z-index: -2;
	}
	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		right: 0;
		width: 0%;
		height: 100%;
		background-color: #8870AC;
		transition: all .3s;
		border-radius: 10rem;
		z-index: -1;
	}

	&:hover {
		color: rgb(255, 255, 255);
		&:before {
			width: 100%;
		}
	}
}


@media (max-width: 768px) {

    #mock-up-features-wrapper{
        flex-direction: column;
    }
    #mock-up-wrapper{
        width: 65%;
        margin: 2% 17.5% 0 17.5%;
    }

    #projectpage-intro{
        font-size: max(2.8vw, 15px)
    }

    #projectpage-url{
        font-size: max(2.6vw, 15px)
    }

    #features{
        width: 80%;
        margin: 2% 10% 0 10%;
        font-size: max(2.8vw, 15px);
    }

    #features ul{
        padding: 1%;
    }
    #mockup-credit{
        font-size: 1.2vw;
    }

    #tech-stack-wrapper{
        flex-wrap: wrap;
        margin: 2% 5% 0 5%;
        justify-content: center;
    }

    #tech-stack{
        width: 25vw;
        min-width: 200px;
        height:20vw;
        min-height: 150px;
    }

    #usage-step-wrapper{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #usage-num-description{
        width: 100%;
    }

    #step-num{
        margin: 2% 0 1% 0;
    }
    #step-desc{
        padding:0 5px 0 5px;
        font-size: max(2.8vw, 15px);
    }
    

    #usage-img{
        width: 97%;
        margin: 0 5px 5px 5px;

    }

}