#contact-wrapper{
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3% 5% 0 5%;
}

#contact-me-text-wrapper{
    width:55%;
}

#contactmetext{
    font-size: 1.6vw;
}

#contact-details-wrapper{
    padding: 3%;
    display: flex;
    flex-direction: column;
}

#contact-details-wrapper a{
    display: block;
    color: #DF599E;
    font-size: 1.6vw;
    text-decoration: none;
    margin: 2% 0 1% 0 ;
    position: relative;
    width: fit-content;
}

#contact-details-wrapper a:hover {
    color: #ac93cd;
  }

#contact-details-wrapper a::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 4px;
    bottom: 0;
    background-color: #DF599E;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }

#contact-details-wrapper a:hover::before {
    transform: scaleX(1);
  }

#typing-text{
    font-size: 3vw; 
    font-weight: 600;
}
  
  @keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
  }
  
  .typewriter {
    --caret: #DF599E;
  }
  
  .typewriter::before {
    content: "";
    animation: typing 13.5s infinite;
  }
  
  .typewriter::after {
    content: "";
    border-right: 2px solid var(--caret);
    animation: blink 0.5s linear infinite;
  }


  @keyframes typing {
    0.0%, 18.3% { content: ""; }
    1.1%, 17.2% { content: "o"; }
    2.2%, 16.1% { content: "on"; }
    3.3%, 15% { content: "onn"; }
    4.4%, 13.9% { content: "onne"; }
    5.5%, 12.8% { content: "onnec"; }
    6.6%, 11.7% { content: "onnect!"; }

    21.54%, 48.64% { content: ""; }
    22.64%, 47.54% { content: "o"; }
    23.74%, 46.44% { content: "ol"; }
    24.84%, 45.34% { content: "oll"; }
    25.94%, 44.24% { content: "olla"; }
    27.04%, 43.14% { content: "ollab"; }
    28.14%, 42.04% { content: "ollabo"; }
    29.24%, 40.94% { content: "ollabor"; }
    30.34%, 39.84% { content: "ollabora"; }
    31.44%, 38.74% { content: "ollaborat"; }
    32.54%, 37.64% { content: "ollaborate!"; }

    51.88%, 67.98% { content: ""; }
    52.98%, 66.88% { content: "r"; }
    54.08%, 65.78% { content: "re"; }
    55.18%,	64.68% { content: "rea"; }
    56.28%,	63.58% { content: "reat"; }
    57.38%,	62.48% { content: "reate!"; }

    71.22%,	82.92% { content: ""; }
    72.32%,	81.82% { content: "h"; }
    73.42%,	80.72% { content: "ha"; }
    74.52%,	79.62% { content: "hat!"; }

    84.02%,	95.72% { content: ""; }
    85.12%,	94.62% { content: "o"; }
    86.22%,	93.52% { content: "od"; }
    87.32%,	92.42% { content: "ode!"; }
  }
  


  @media (max-width: 768px) {

    #contact-wrapper{
        flex-direction: column;
    }

    #contact-me-text-wrapper{
        text-align: center;
    }
    

    #contact-details-wrapper{
        align-items: center;
        text-align: center;
        padding: 0;
    }

    #contact-details-wrapper a,
    #contactmetext{
        font-size: max(3vw, 16px)
    }

  #typing-text{
    font-size: max(4.5vw, 20px)
}
  }