
#portfolio-display-wrapper{
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    display: flex;
    margin: 2%
}

.project-landing-wrapper{
    width: 45%;
    background-color: #292939;
    border-radius: 5px;
    position: relative;
    margin: 1%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.project-landing-img{
    width: 95%;
    margin-top: 2%;
    border-radius: 5px;
}

.project-tile{
    font-size: max(1.8vw, 18px);
    margin: 2%;
    text-decoration: none;
    color:#DF599E;
    position: relative;
}

.project-tile:hover {
    color: #ac93cd;
  }

.project-tile::before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    background-color: #DF599E;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }

.project-tile:hover::before {
    transform: scaleX(1);
  }

.project-description{
    width: 95%;
    min-height: 80px;
    text-align: center;
    margin: 0 0 2% 0;
    font-size: max(1.4vw, 15px);
    display: flex;
    flex-direction: column;
    font-weight: 300;
    align-items: center;
}


#learnmore-btn{
	display:flex;
    width: 30%;
    justify-content: center;
    align-items: center;
    font-weight: 400;
	padding: .50rem 0.75rem;
	border-radius: 10rem;
	color: #fff;
    margin: 2% 0 0 0;
    text-decoration:none;
	transition: all .3s;
	position: relative;
	overflow: hidden;
	z-index: 1;
	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #8f3965;
		border-radius: 10rem;
		z-index: -2;
	}
	&:before {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		width: 0%;
		height: 100%;
		background-color: #8870AC;
		transition: all .3s;
		border-radius: 10rem;
		z-index: -1;
	}

	&:hover {
		color: rgb(255, 255, 255);
		&:before {
			width: 100%;
		}
	}
}


@media (max-width: 768px) {
    .project-landing-wrapper{
        min-width: 90%;
    }

    #my-projects-title{
        font-size: 6.5vw;
    }

}





